@import '../../_variables.scss';
@import '../../_mixins.scss';

.default {
  @include brandonGrotesque();
  text-transform: uppercase;
  color: $primary-text-color;
  margin: 0;
  padding: 0;
}
