@import '../../_variables.scss';
@import '../../_mixins.scss';

.profile {
  margin-top: 1rem;
}

.fullWidth {
  width: 100%;
}

.helpText {
  @include proximaNova();
  font-size: .938rem;
  color: $primary-grey;
  margin: 0 0 1.2rem 0;
  text-align: center;
}

.loginForm {
  & > div {
    margin-bottom: .5rem;
  }
}

.referenceNr {
  position: absolute;
  bottom: 50px;
  width: 100%;
  left: 0;
  text-align: center;
  @include proximaNova();
}

.snippetMessage {
  text-align: center;
  @include proximaNova();
}
