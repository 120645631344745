@import '../../../_variables.scss';
@import '../../../_mixins.scss';

.field {
  @include proximaNova();
}


.label {
  @include proximaNova();
  color: $primary-grey;
  font-size: 18px;
  letter-spacing: 0.48px;
  line-height: 36px;
  display: flex;
  flex-direction: column;

  .mandatory {
    color: $red;
  }

  select {
    padding: 5px 0;
    border: 1px solid $primary-grey;
    border-radius: 0;

    option, optgroup {
      @include proximaNova();
    }

    @include proximaNova();
  }
}

.error {
  border-color: $primary-red;
}
