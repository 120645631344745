@import '../../_variables.scss';
@import '../../_mixins.scss';

.similarProjects {
  :global {
    .slick-dots {
      button {
        &::before {
          font-size: 9px;
          color: $grey-45;
          opacity: 1;
        }
      }

      .slick-active {
        button {
          &::before {
            color: $primary-red;
            opacity: 1;
          }
        }
      }
    }
  }
}
