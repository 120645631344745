@import '../../../_variables.scss';
@import '../../../_mixins.scss';

.field {
  @include proximaNova();
  width: 100%;
  border: 2px solid $primary-grey;
  padding: 8px 0 8px 8px;
  -webkit-appearance: none !important;

  &:disabled {
    background-color: $grey-25;
  }
}

.label {
  @include proximaNova();
  color: $primary-grey;
  font-size: 18px;
  letter-spacing: 0.48px;
  line-height: 36px;

  .mandatory {
    color: $red;
  }

  &.noLabel {
    margin-top: 36px;
    display: block;
  }

  &.side {
    display: flex;
    margin-bottom: 15px;

    @include media('<tablet') {
      display: block;
    }

    input {
      max-width: 50%;
      margin-left: auto;

      @include media('<tablet') {
        max-width: 100%;
      }
    }
  }
}

.error {
  border-color: $primary-red;
}
