@import '../../_variables.scss';
@import '../../_mixins.scss';

.spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: calc(50% - 2.5rem);
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.inner1, .inner2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $primary-grey;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.inner2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}
