@import '../../_variables.scss';
@import '../../_mixins.scss';

.bg {
  background: rgba(0, 0, 0, .5);
  position: absolute;
  z-index: 150;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.hidden {
  display: none;
}

.wrapper {
  position: relative;
  background: #fff;
  text-align: left;
  max-width: 900px;
  width: 100%;
  padding: 3rem 4rem;

  @include media('<=tablet') {
    padding: 1.5rem 2rem;
  }
}

.title {
  @include brandonGrotesque();
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.063rem;
  color: $primary-grey;
}

.text {
  @include proximaNova();
  color: $primary-grey;
  margin-top: 1.5rem;
}

.button {
  margin-top: 1.5rem;
  text-align: center;
}

.footer {
  margin-top: 1.5rem;
  text-align: center;
  @include proximaNova();
  color: $grey-25;
}

.settings {
  span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.links {
  margin-top: 1.5rem;

  a {
    text-decoration: underline;
    margin: 0 1rem;
    color: $grey-25;

    &:hover {
      cursor: pointer;
      text-decoration: none !important;
    }
  }
}

.checkbox {
  display: flex;
  margin-top: 20px;

  & > div {
    &:hover {
      cursor: pointer;
    }
  }

  input[type="checkbox"] {
    display:none;
  }

  input[type="checkbox"] + label {
    width: 60px;
    min-width: 60px;
    height: 30px;
    border-radius: 30px;
    border: 2px solid #ddd;
    background-color: #EEE;
    display: inline-block;
    content: "";
    margin-right: 15px;
    transition: background-color 0.3s linear;
    margin-top: 10px;
  }

  input[type="checkbox"] + label:hover {
    cursor: pointer;
  }

  input[type="checkbox"] + label::before {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #fff;
    display: block;
    content: "";
    margin-right: 5px;
    transition: margin 0.1s linear;
    box-shadow: 0px 0px 5px #aaa;
  }

  input[type="checkbox"]:checked+label{
    background-color: $primary-red;
  }

  input[type="checkbox"]:disabled+label{
    background-color: $primary-red;
    opacity: .7;
  }

  input[type="checkbox"]:checked+label::before {
    margin: 0 0 0 30px;
  }
}
