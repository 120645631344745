@import '../../_variables.scss';
@import '../../_mixins.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;


  .animation {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &.modalBlack {

    .modalContent {
      width: 30%;
      background-color: $primary-grey;
      color: $white;
      min-height: 30%;


      @include media('<=lg') {
        width: 40%;
      }

      @include media('<=desktop') {
        width: 50%;
      }

      @include media('<=tablet') {
        width: 95%;
      }

    }

    .closeIcon {
      fill: $white;
      color: $white;
    }

    h1 {
      color: $white;
    }
  }
}

.modalShow {
  opacity: 1;
  pointer-events: visible;
}

.modalContent {
  background-color: $white;
  width: 55%;
  position: relative;
  padding-bottom: 40px;
  margin: auto;

  @include media('<=desktop') {
  width: 90% !important;
}

  @include media('<=tablet') {
    width: 95% !important;
  }
}

.modalHeader {
  min-height: 78px;
  padding: 20px;

  h1 {
    padding-right: 30px;
  }
}

.modalBody {
  padding: 0 20px;
  position: relative;
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 25px;

  @include media('<=phone') {
    right: 0px;
    top: -34px;
    width: 40px;
    padding: 5px 10px;
    background: $white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}
