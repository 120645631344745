@import '../../_variables.scss';
@import '../../_mixins.scss';

.projectFavorite {
  position: relative;
  opacity: 1;
}

.icon {
  position: absolute;
  top: .3rem;
  right: .9rem;
  z-index: 2;
  @include noSelect();

  svg {
    width: 1.5rem;
    height: 1.5rem;
    
    &:hover {
      cursor: pointer;

      line, path {
        fill: $primary-grey;
      }
    }
  }
}

.fadeOut {
  opacity: 0 !important;
  transition: opacity 200ms ease-out;
}
