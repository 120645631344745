@import '../../_variables.scss';
@import '../../_mixins.scss';

.grid {
  display: grid;
  grid-gap: 10px;
  width: 100%;

  @include media('<=desktop') {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  @include media('<=tablet') {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }



}