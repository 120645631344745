@import '../../_variables.scss';
@import '../../_mixins.scss';

.techdocs {
  width: 100%;
  max-width: 100%;
  margin-top: 1rem;

  @include media('<=tablet') {
    margin-top: 1rem;
  }
}

.login {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.file {
  @include proximaNova();
  color: $grey-85;

  &:hover {
    color: red;
    cursor: pointer;
  }
}

.loading {
  & > div {
    a {
      span {
        color: $grey-45 !important;
      }

      svg {
        line, path {
          fill: $grey-45 !important;
        }
      }

      &:hover {
        cursor: default !important;
      }
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  @include noSelect();
}

.iconLeft {

  svg {
    fill: $primary-text-color;
  }

  &:hover {
    cursor: pointer;
  }


  svg {
    width: 1rem;
    height: 1rem;
  }
}

.page {
  margin: 0 1rem;
  position: relative;
  top: -1px;
  color: $primary-text-color;
  @include brandonGrotesque();
}

.iconRight {

  svg {
    fill: $primary-text-color;
  }

  &:hover {
    cursor: pointer;
  }
  
  svg {
    transform: rotate(180deg);
    width: 1rem;
    height: 1rem;
  }
}

.noFiles {
  @include proximaNova();
  color: $primary-text-color;
}
