@import '../../_variables.scss';
@import '../../_mixins.scss';

.wrapper {
  padding-bottom: 4rem;
  min-height: 100%;
  position: relative;
}

.title {
  @include brandonGrotesque();
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: $primary-text-color;
  font-size: 1.4rem;
  letter-spacing: .1rem;
}
