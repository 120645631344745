@import '../../_variables.scss';
@import '../../_mixins.scss';


.bubble {
  position: absolute;
  left: 20px;
  top: 20px;
  background: #3BA9FF;
  color: $white;
  z-index: 151;
  padding: 20px;
  border-radius: 20px;
  max-width: 350px;
  @include proximaNova();
  box-shadow: 0 3px 6px #00000029;

  p {
    font-size: .9rem;
  }

  &.step1 {
    left:60px;
    top: 60px;
  }

  &.step2 {
    left: -390px;
    top: -155%;
    display: block;
    width: 350px;
  }

  &.step3 {
    left: -390px;
    top: -300%;
    display: block;
    width: 350px;
  }

  &.step4 {
    left: calc(100% - 460px);
    top: calc(100% - 250px);
  }

  &.step5 {
    left: calc(100% - 600px);
    top: calc(100% - 195px);
  }

  &.step6 {
    left: calc(100% - 460px);
    top: calc(55px);
  }

  &.step7 {
    left: calc(100% - 460px);
    top: calc(120px);
  }

  &.step8 {
    left: calc(100% - 460px);
    top: calc(185px);
  }

  &.step9 {
    left: calc(100% - 460px);
    top: calc(245px);
  }

  &.step10 {
    left: calc(100% - 460px);
    top: calc(310px);
  }

  &.step11 {
    left: calc(100% - 460px);
    top: calc(375px);
  }

  &.step12 {
    left: calc(100% - 460px);
    top: calc(100% - 315px);
  }

  &.step13 {
    left: calc(100% - 460px);
    top: calc(100% - 186px);
  }

  &.step14 {
    left: calc(100% - 535px);
    top: calc(53px);
  }

  .wrapper {
    position: relative;

    > span {
      :hover {
        cursor: pointer;
      }
    }
  }
  .closeIcon {
    position: absolute;
    color: $white;
    max-width: 15px;
    right: -4px;
    top: -30px;

  }
}

.tourStep {
  display: flex;
  justify-content: right;
  align-items: center;

  p {
    padding: 0 0 0 20px;
    margin: 0;

  }
}

.triangleBottom {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 56px 31px 0;
  border-color: transparent transparent #3BA9FF transparent;
  transform: rotate(0deg);
  right: -59px;
  bottom: -20px;
  position: absolute;
  z-index: -1;
}

.triangleTop {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 31px 56px 0 0;
  border-color: #3BA9FF transparent transparent transparent;
  transform: rotate(0deg);
  right: -59px;
  top: -34px;
  position: absolute;
  z-index: -1;
}

.buttons {
  display: flex;
  width: 100%;

  .rightButton {
    margin-left: auto;
  }
}