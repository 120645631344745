@import '../../_variables.scss';
@import '../../_mixins.scss';

.overlay {
  position: absolute;
  height: 100%;
  background: $primary-overlay-background;
  top: 0;
  z-index: 100;
  transform: translateX(100%);
  transition: all 300ms ease-out;
  overflow-y: scroll;

  @include media('>=1599px') {
    min-width: 1200px;
    right: 0;
  }

  @include media('<1599px') {
    width: 0;
    right: 0;
  }
}

.open {
  width: 100%;
  transform: translateX(0%);
  transition: transform 300ms ease-in;

  @include media('>=1599px') {
    width: 60%;
    right: 6rem;
    box-shadow: 1px 0 30px 1px $primary-overlay-shadow;
  }
}

.close {
  position: sticky;
  top: 2rem;
  left: 2rem;
  padding: .75rem .75rem .5rem .75rem;
  border-radius: 5px;
  background: $primary-grey;
  width: 2.9rem;
  z-index: 9999;

  @include media('<=tablet') {
    top: 1rem;
    left: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;

    line {
      stroke: #fff !important;
    }
  }
}

.contentHolder {
  display: flex;
  justify-content: center;
  width: 100%;
  background: $primary-overlay-background;
}

.content {
  padding: 0 11rem 2rem 4.8rem;
  margin-top: 6rem;
  max-width: 1200px;
  width: 100%;
  background: $primary-overlay-background;

  @include media('<=tablet') {
    padding: 0 1rem 2rem 1rem;
    margin-top: 4rem;
  }
}
