@import '../../_variables.scss';
@import '../../_mixins.scss';

.tiles {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem 8rem 2rem 2rem;
  overflow: auto;
  height: 100%;
  width: calc(100% - 6rem);
  transition: background 50ms linear;
  background: $primary-overlay-background;

  &.balcony {
    background: $pr-balcony;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.cero {
    background: $pr-cero;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.bifolding {
    background: $pr-bifolding;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.sliding {
    background: $pr-sliding;
  }

  &.roof {
    background: $pr-roof;
  }

  &.wintergarten {
    background: $pr-wintergarten;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }


  &.hsd {
    background: $pr-hsd;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.apartment {
    background: $tp-apartment-building;
  }

  &.commercial {
    background: $tp-commercial-and-industrial;
  }

  &.culture {
    background: $tp-culture-and-sacred-buildings;
  }

  &.education {
    background: $tp-education-and-science;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.facade {
    background: $tp-facade;
  }

  &.health {
    background: $tp-health-and-care;
  }

  &.hotels {
    background: $tp-hotels-and-gastronomy;
  }

  &.living {
    background: $tp-living-and-garden;
  }

  &.office {
    background: $tp-office-and-administration;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.other {
    background: $tp-other;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.stadiums {
    background: $tp-stadiums-and-arenas;
  }

  &.wellness {
    background: $tp-wellness-and-fitness;
  }

  .filters {
    z-index: 1;
    margin: 0 1rem;
  }

  .filter {
    background-color: $primary-teaser-hover;
    border: 2px solid $primary-filter-color;
    padding: 0 15px 4px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    @include proximaNova();
    color: $primary-text-color;
    display: inline-block;
    line-height: 28px;
    white-space: nowrap;

    @include media('<=tablet') {
      font-size: 12px;
      line-height: 18px;
      padding-top: 4px;
    }
  }

  .closeIcon {
    color: $primary-filter-color;
    width: 12px;
    font-weight: bold;
    margin: 0 0 -12px 13px;
    cursor: pointer;

    @include media('<=tablet') {
      height: 12px;
      margin: 0 0 -3px 13px;
    }
  }

  @include media('<=desktop') {
    padding: 2rem 8rem 2rem 2rem;
  }

  @include media('<=tablet') {
    padding: 4.7rem .8rem 2rem .8rem;
    width: 100%;
  }

  & > div > div {
    display: flex;
    flex-wrap: wrap;
  }

  & > div > div > div {
    width: 25%;
    padding: 1rem;
    margin-bottom: 1rem;

    @include media('<=desktop') {
      width: 50%;
    }

    @include media('<=tablet') {
      width: 100%;
      padding: 0;
    }

    &:hover {
      background: $primary-teaser-hover;
      transition: background 50ms linear;

      img {
        transform: scale(1.02);
      }
    }
  }
}

