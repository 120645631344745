@import '../../_variables.scss';
@import '../../_mixins.scss';

.marker {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -100%);
  filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.3));
  position: relative;
  top: 0.825rem;
  left: 1.6875rem;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 1.563rem solid transparent;
    border-right: 1.563rem solid transparent;
    border-top: 3.125rem solid transparent;
    position: absolute;
    top: 2.5rem;
    left: 0.188rem;
  }
}

.open {
  filter: none;
  position: relative;
  z-index: 10;
}

.svg {
  width: 1.5rem;
  height: 1.5rem
}

.icon {
  position: relative;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.2);
}

.apartmentBuilding {
  background: $tp-apartment-building;

  &:after {
    border-top-color: $tp-apartment-building;
  }

  svg path, svg line {
    stroke: #fff;
  }
}

.commercialAndIndustrial {
  background: $tp-commercial-and-industrial;

  &:after {
    border-top-color: $tp-commercial-and-industrial;
  }

  svg path, svg line, svg rect {
    stroke: #fff;
  }
}

.cultureAndSacredBuildings {
  background: $tp-culture-and-sacred-buildings;

  &:after {
    border-top-color: $tp-culture-and-sacred-buildings;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.educationAndScience {
  background: $tp-education-and-science;

  &:after {
    border-top-color: $tp-education-and-science;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.facade {
  background: $tp-facade;

  &:after {
    border-top-color: $tp-facade;
  }

  svg path, svg line {
    stroke: #fff;
  }
}

.healthAndCare {
  background: $tp-health-and-care;

  &:after {
    border-top-color: $tp-health-and-care;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.hotelsAndGastronomy {
  background: $tp-hotels-and-gastronomy;

  &:after {
    border-top-color: $tp-hotels-and-gastronomy;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.livingAndGarden {
  background: $tp-living-and-garden;

  &:after {
    border-top-color: $tp-living-and-garden;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.officeAndAdministration {
  background: $tp-office-and-administration;

  &:after {
    border-top-color: $tp-office-and-administration;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.other {
  background: $tp-other;

  &:after {
    border-top-color: $tp-other;
  }

  svg path, svg line, svg rect, svg circle {
    fill: #fff;
  }
}

.stadiumsAndArenas {
  background: $tp-stadiums-and-arenas;

  &:after {
    border-top-color: $tp-stadiums-and-arenas;
  }

  svg path, svg line, svg rect, svg circle, svg ellipse {
    stroke: #fff;
  }
}

.wellnessAndFitness {
  background: $tp-wellness-and-fitness;

  &:after {
    border-top-color: $tp-wellness-and-fitness;
  }

  svg path, svg line, svg rect, svg circle, svg ellipse {
    stroke: #fff;
  }
}

.new {
  position: absolute;
  @include brandonGrotesque();
  text-transform: uppercase;
  font-size: 10px;
  color: $white;
  background: $primary-red;
  padding: 9px 3px;
  border-radius: 37px;
  right: -12px;
  top: 4px;
  font-weight: 800;
  z-index: 2;
  line-height: 8px;
}
