@import '../../../_variables.scss';
@import '../../../_mixins.scss';

.field {
  @include proximaNova();
}

.checkboxLabel {
  @include proximaNova();
  color: $primary-grey;
  font-size: 18px;
  letter-spacing: 0.48px;
  line-height: 36px;
  position: relative;
  padding-left: 40px;
  display: flex;

  span {
    line-height: 28px;
  }

  &:hover {
    cursor: pointer;
  }

  .checkbox {
    display: none;
  }

  .checkMark {
    width: 30px;
    height: 30px;
    background-color: $white;
    border: 1px solid $primary-grey;
    position: absolute;
    left: 0;
    display: inline-block;
    top: 0;
    border-radius: 3px;
  }

  .checkbox:checked + .checkMark {
    transition: .1s;
  }

  .checkbox:checked + .checkMark:after {
    content: "";
    position: absolute;
    width: 10px;
    transition: .1s;
    height: 5px;
    top: 45%;
    left: 50%;
    border-left: 2px solid $primary-grey;
    border-bottom: 2px solid $primary-grey;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}


.error {
  border-color: $primary-red;
}
