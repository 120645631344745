@import '../../_variables.scss';
@import '../../_mixins.scss';

.products {
  margin-top: 2rem;
  @include noSelect();
  position: relative;
}

.product {
  display: flex;
  align-items: center;
  color: $primary-text-color;

  & > svg {
    height: 2.25rem;
    width: 2.25rem;
    margin-left: -1rem;
    padding-right: .75rem;

    path {
      &:first-of-type {
        fill: $primary-text-color !important;
      }
    }
  }

  & > div {
    @include brandonGrotesque();
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $primary-grey;

    @include media('<lg') {
      font-size: .85rem;
    }

    @include media('<tablet') {
      font-size: 1.2rem;
      font-weight: normal;
      text-transform: none;
      @include proximaNova();
    }
  }
}

.removeFilters {
  width: 28px;
  position: absolute;
  left: 0;
  top: -55px;

  &:hover {
    cursor: pointer;
  }
}

.productName {
  color: $primary-text-color !important;
}

.filterGroup {
  margin-left: 4rem;
  margin-top: 1.5rem;
}

.filterGroupName {
  @include proximaNova();
  font-weight: bold;
  margin-bottom: 1rem;
  color: $primary-text-color;
}

.systemName {
  @include proximaNova();
  margin-left: -1.25rem;
  color: $primary-text-color;
}
