@import '../../_variables.scss';
@import '../../_mixins.scss';

.tabs {
  width: 100%;
  max-width: 100%;
}

.navigation {
  display: flex;

  @include media('<=desktop') {
    flex-wrap: wrap;
  }
}

.navigationItem {
  width: 100%;
  border-bottom: 2px solid transparent;
  text-align: center;
  @include proximaNova();
  text-transform: uppercase;
  padding: 1rem 2rem;
  letter-spacing: .1rem;
  color: $primary-text-color;

  &:hover {
    cursor: pointer;
  }

  @include media('<=desktop') {
    border: 1px solid $primary-text-color;
    width: 100%;
    padding: .5rem;
    display: flex;
    justify-content: center;
    margin-bottom: .5rem;
  }
}

.navigationItemActive {
  border-color: $primary-text-color;
  font-weight: bold;
}

.content {

}
