@import '../../_variables.scss';
@import '../../_mixins.scss';

.favorites {
  margin-top: 1.5rem;
}

.favoritesGroup, .proposed {
  display: flex;
  margin: 1.5rem 0;
  flex-wrap: wrap;
}

.favoritesGroupTitle {
  @include proximaNova();
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-weight: bold;
  display: flex;
  line-height: 2.7rem;
  margin-bottom: 1rem;
  color: $primary-text-color;

  .favoritesGroupTitleText {
    &:hover {
      cursor: pointer;
    }
  }

  svg {
    background: $grey-45;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 11px;
    margin-right: 15px;

    &.expanded {
      background: $primary-red;
      padding: 12px;
    }

    &:not(.expanded) {
      &:hover {
        cursor: pointer;
      }
    }


    path {
      fill: $white
    }
  }
}

.unauthenticated {
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
}

.loadMore {
  width: 100%;
  text-align: center;
}

.favoritesExport {
  position: sticky;
  bottom: 70px;
}

.favoritesExportWrapper {
  position: relative;
}

.favoritesExportBubble {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  background: $primary-red;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 1.65rem;
  height: 1.65rem;
  padding: 5px 8px;
  color: $white;
  @include proximaNova();
  border-radius: 50%;
}

.pdfDownloadLink {
  @include brandonGrotesque();
  text-transform: uppercase;
  background: $grey-10;
  border-color: $grey-10;
  color: $grey-85;
  font-size: .8rem;
  padding: 0.5rem 1.5rem;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  right: 0;

  &:hover {
    background: $grey-25;
    border-color: $grey-25;
  }
}
