@import '../../_variables.scss';
@import '../../_mixins.scss';

.gallery {
  opacity: 0;
  display: flex;
  min-height: 27.88rem;
  @include noSelect();

  @include media('<=tablet') {
    flex-direction: column;
    min-height: unset;
  }
}

.fadeIn {
  opacity: 1;
  transition: 500ms ease-in;
}

.currentImage {
  width: 70%;
  opacity: 1;
  transition: 500ms ease-in;

  &:hover {
    cursor: zoom-in;
  }

  @include media('<=tablet') {
    width: 100%;
    display: none;
  }

  img {
    max-width: 100%;
    display: flex;
    margin: 0 auto;
  }
}

.modalCurrentImage {
  width: 100%;

  @include media('<=tablet') {
    width: 100%;
  }

  img {
    max-width: 100%;
    display: flex;
    margin: 0 auto;
  }
}

.navigation {
  width: 30%;
  position: relative;
  overflow: hidden;

  @include media('<=tablet') {
    width: 100%;
    display: none;
    justify-content: space-between;
  }
}

.navigationMobile {
  position: relative;
  width: 100%;

  @include media('>=tablet') {
    display: none;
  }

  .navigationList {
    display: block;
    position: relative;
    width: auto;
    height: auto;
  }

  .slickImage {
    img {
      width: 100%;
    }
  }

  :global {
    .slick-dots {
      button {
        &::before {
          font-size: 9px;
          color: $grey-45;
          opacity: 1;
        }
      }

      .slick-active {
        button {
          &::before {
            color: $primary-red;
            opacity: 1;
          }
        }
      }
    }
  }
}

.navigationList {
  position: absolute;
  height: 100%;
  width: 100%;

  @include media('<=tablet') {
    position: relative;
    display: flex;
  }

  @include media('<=446px') {
    width: 85%;
  }
}

.navigationThumb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  transition: 300ms linear;
  box-sizing: border-box;
  padding: 2rem;

  @include media('<=tablet') {
    padding: 2rem;
    height: 100%;
    flex: 0 0 50%;
  }

  img {
    max-width: 100%;
    border: 2px solid transparent;

    &:hover {
      cursor: pointer;
    }
  }
}

.navigationThumbActive {
  img {
    border-color: $primary-grey;
  }
}

.navigationIcon {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;

  @include media('<=tablet') {
    position: relative;
    width: auto;
    align-items: center;

    svg {
      transform: rotate(-90deg);
    }
  }

  svg {
    &:hover {
      cursor: pointer;

      path {
        fill: $primary-red;
      }
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.navigationIconDisabled {
  svg {
    &:hover {
      cursor: default !important;
    }
  }

  svg {
    path {
      fill: #c2c2c2 !important;
    }
  }
}

.up {
  top: 0;
}

.down {
  bottom: 0;

  svg path {
    fill: $primary-grey;
  }
}

.modalNavigationIcon {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  user-select: none;

  svg {
    height: 56px;
    width: 35px;

    @include media('<=tablet') {
      height: 35px;
      width: 25px;
    }

    path {
      fill: $white;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.modalNavigationPrev {
  left: 40px;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;

  @include media('<=tablet') {
    left: 25px;
  }
}

.modalNavigationNext {
  right: 40px;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;

  @include media('<=tablet') {
    right: 25px;
  }
}
