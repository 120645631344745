@import '../../_variables.scss';
@import '../../_mixins.scss';

.wrapper {

}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.grey-10 {
    background: $grey-10;
    margin-top: 20px;
    padding: 10px 10px 10px 0;
  }

  & > svg {
    width: 1.25rem;
    height: 1.25rem;

    path {
      fill: $primary-grey !important;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.flipAngle {
  transform: rotate(180deg);
}

.body {
  max-height: 0;
  transition: max-height 150ms ease-out;
  overflow: hidden;
}

.open {
  max-height: 10000px;
  transition: max-height 250ms ease-in;
}
