@import '../../_variables.scss';
@import '../../_mixins.scss';

.form {
  & > div {
    margin-bottom: 1rem;
  }

  & > span {
    @include proximaNova();
    font-size: .938rem;
    margin: 0 0 .2rem 0;
    color: $primary-red;
    display: block;
  }
}

.iconGroup {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    left: .7rem;
    font-size: 1.4rem;
  }
}

.signIn {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: -.5rem;
}

.register {
  text-align: center;
  margin-top: 1.5rem;
  color: $primary-grey;

  p {
    @include proximaNova();
    font-size: .938rem;
  }

  a {
    @include brandonGrotesque();
    text-transform: uppercase;
    color: $primary-grey;
    border: 2px solid $primary-grey;
    text-decoration: none;
    font-weight: bold;
    font-size: .938rem;
    display: inline-block;
    background: #fff;
    padding: .625rem 1.875rem;
    
    &:hover {
      cursor: pointer;
      background: $primary-grey;
      color: #fff;
    }
  }
}

.resetPassword {
  @include proximaNova();
  font-size: .938rem;
  position: absolute;
  top: 0;
  right: 0;
  color: $primary-grey;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.submit {
  margin-top: 2.5rem;
}
