@import '../../_variables.scss';
@import '../../_mixins.scss';

.default {
  @include proximaNova();
  color: $primary-text-color;
  margin: 0;
  padding: 0;
  font-size: 18px;
  white-space: pre-line;
}
