$white: #ffffff;
$grey-85: #4a4a49;
$grey-65: #7d7c7d;
$grey-45: #a8a8a7;
$grey-25: #c2c2c2;
$grey-10: #e6e6e6;
$red: #D51130;
$green: #4caf50;

$primary-grey: var(--primary-color);
$primary-red: var(--primary-red);
$primary-green: var(--primary-green);
$primary-red-accent: var(--primary-red-accent);
$primary-overlay-background: var(--primary-overlay-background);
$primary-text-color: var(--primary-text-color);
$primary-overlay-shadow: var(--primary-overlay-shadow);
$primary-teaser-hover: var(--primary-teaser-hover);
$primary-mobile-menu: var(--primary-mobile-menu);
$primary-filter-color: var(--primary-filter-color);

// Typology colors
$tp-apartment-building: #FDEB85;
$tp-commercial-and-industrial: #FDCE9D;
$tp-culture-and-sacred-buildings: #DBC0ED;
$tp-education-and-science: #839503;
$tp-facade: #A5D6FB;
$tp-health-and-care: #FDCBD4;
$tp-hotels-and-gastronomy: #C1A6A4;
$tp-living-and-garden: #CEDB75;
$tp-office-and-administration: #E07B3D;
$tp-other: #6BA3CE;
$tp-stadiums-and-arenas: #CECBD6;
$tp-wellness-and-fitness: #BCDEC3;

//Product Colors
$pr-balcony: #5e838f;
$pr-cero: #8b8c8c;
$pr-bifolding: #aca761;
$pr-hsd: #d67a6d;
$pr-sliding: #d4c9a8;
$pr-roof: #edbd6f;
$pr-wintergarten: #bc8e71;


body{
  --primary-red: #{$red};
  --primary-green: #{$green};
  --primary-red-accent: #{$red};
  --primary-color: #{$grey-85};
  --primary-overlay-background: #{$white};
  --primary-text-color: #{$grey-85};
  --primary-overlay-shadow: #{$grey-45};
  --primary-teaser-hover: #{$grey-10};
  --primary-mobile-menu: #{$grey-85};
  --primary-filter-color: #{$grey-65};
}
body.dark{
  --primary-red: #{$red};
  --primary-red-accent: #{$white};
  --primary-color: #{$grey-25};
  --primary-text-color: #{$white};
  --primary-overlay-background: #{$grey-85};
  --primary-overlay-shadow: #{$grey-85};
  --primary-teaser-hover: #{$grey-65};
  --primary-mobile-menu: #{$grey-85};
  --primary-filter-color: #{$grey-65};
}
