@import '../../_variables.scss';
@import '../../_mixins.scss';

.history {

}

.headline {
  @include brandonGrotesque();
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: $primary-text-color;
  font-size: 1.4rem;
  letter-spacing: .1rem;
}

.dayWrapper {
  @include proximaNova();
  display: flex;
  margin: 50px 0;
  color: $primary-text-color;
}

.day {

}

.objects {
  font-style: italic;
}

.items {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 40px;

  @include media('<=tablet') {
    grid-template-columns: 90%;
  }
}

.item {
  @include brandonGrotesque();
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: $primary-grey;
  font-size: .6rem;
  letter-spacing: .1rem;
}

.image {
  max-width: 100%;
}

.titleAction {
  display: flex;
  align-items: center;
  color: $primary-text-color;

  h2 {
    margin-right: 10px;
    color: $primary-text-color;
  }
}

.title {
  color: $primary-text-color;
}

.leftBar {
  padding-right: 30px;


  svg {
    background: $grey-45;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 15px;

    &.expanded {
      background: $primary-red;
      padding: 12px;
    }

    &:hover {
      cursor: pointer;
    }


    path {
      fill: $white
    }
  }
}

.greyBar {
  background: $grey-45;
  height: calc(100% - 20px);
  width: 3px;
  margin: 0 auto;
}

.content {

}

.smallTitle {
  &:hover {
    cursor: pointer;
  }
}

.heading {
  display: flex;

  @include media('<=tablet') {
    display: block;
    h1 {
      margin-bottom: 15px;
    }
  }

  button {
    margin-left: auto;
  }
}

.trash {
  margin-left: auto;

  svg {
    width: 18px;
  }
}

.modalTitle {
  @include brandonGrotesque();
  font-weight: bold;
  text-transform: uppercase;
  //text-align: left;
  color: $white;
  font-size: 1.2rem;
  letter-spacing: .1rem;
  line-height: 2.2rem;
  display: flex;
  text-align: right;


  span {
    margin-left: auto;
  }

  svg {
    line-height: 2.2rem;
    padding-right: 10px;
    margin-right: 10px;
    width: 40px;
  }
}

.deleteContent {
  padding: 30px 20px 0 50px;
}

.deleteWrapper {
  display: flex;
  margin: 40px 0 40px 50px;
}

.deleteLabel {
  @include proximaNova();
  margin-right: 15px;
  font-weight: 700;
  font-style: italic;
  padding-top: 10px
}

.deleteBox {
  @include proximaNova();
  position: relative;
  display: flex;
  min-width: 60%;
  margin-left: auto;
  border: 2px solid $grey-45;
  padding: 5px 10px;

}

.deleteSelect {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100% + 4px);
  line-height: 29px;
  border: 2px solid grey;
  margin: -7px -12px;
  padding: 0 10px;
  background: $grey-85;
}

.hidden {
  display: none;
}

.date {
  &:hover {
    cursor: pointer;
  }
}

.selectedItem {
  display: flex;
  width: 100%;
}

.icons {
  margin-left: auto;
  display: flex;
  flex-direction: column;

  svg {
    width: 11px;
    height: 10px;

    path {
      fill: $white
    }
  }
}

.actionButtons {
  display: grid;
  grid-template-columns: calc(50% - 10px) 50%;
  grid-gap: 10px;
}
