@import '../../_variables.scss';
@import '../../_mixins.scss';

.metaInformation {
  border-left: 3px solid $primary-text-color;
  padding-left: .25rem;
}

.metaInformationName {
  @include brandonGrotesque();
  color: $primary-text-color;
  text-transform: uppercase;
  font-weight: bold;
}

.metaInformationValue {
  @include proximaNova();
  color: $primary-text-color;
}

.metaInformationLinked {
  color: $primary-red-accent;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
