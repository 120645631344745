@import '../../_variables.scss';
@import '../../_mixins.scss';

.typologyFilterItem {
  margin-bottom: 1.25rem;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  padding-right: 1.25rem;
  position: relative;

  svg {
    height: 2rem;
    width: 2rem;
    z-index: 2;
    position: relative;
  }
}

.text {
  @include proximaNova();
  font-size: 1.2rem;
  color: $primary-text-color;
}

.splash {
  width: 1.75rem;
  height: 1.75rem;
  background: $tp-facade;
  position: absolute;
  top: -.25rem;
  left: -.25rem;
  border-radius: 50%;
  z-index: 1;
}

.apartment {
  background: $tp-apartment-building;
}

.commercial {
  background: $tp-commercial-and-industrial;
}

.culture {
  background: $tp-culture-and-sacred-buildings;
}

.education {
  background: $tp-education-and-science;
}

.facade {
  background: $tp-facade;
}

.health {
  background: $tp-health-and-care;
}

.hotels {
  background: $tp-hotels-and-gastronomy;
}

.living {
  background: $tp-living-and-garden;
}

.office {
  background: $tp-office-and-administration;
}

.other {
  background: $tp-other;
}

.stadiums {
  background: $tp-stadiums-and-arenas;
}

.wellness {
  background: $tp-wellness-and-fitness;
}
