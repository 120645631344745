@import '../../_variables.scss';
@import '../../_mixins.scss';

.marker {
  position: relative;
  z-index: 1;

  &:hover {
    cursor: pointer !important;
  }
}

.activeMarker {
  z-index: 2;
}

.cluster {
  &:hover {
    cursor: pointer !important;
  }
}

.filters {
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 1;
  max-width: 80%;

  @include media('<=tablet') {
    max-width: 100%;
    margin-top: 1rem;
  }
}

.filter {
  background-color: $primary-teaser-hover;
  //border: 2px solid $primary-filter-color;
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0,0,0,1);
  box-shadow: 0px 0px 20px -10px rgba(0,0,0,1);
  padding: 0 15px 4px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  @include proximaNova();
  color: $primary-text-color;
  display: inline-block;
  line-height: 28px;
  white-space: nowrap;

  @include media('<=tablet') {
    font-size: 12px;
    line-height: 18px;
    padding-top: 4px;
  }

  &.balcony {
    background: $pr-balcony;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.cero {
    background: $pr-cero;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.bifolding {
    background: $pr-bifolding;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.sliding {
    background: $pr-sliding;
  }

  &.roof {
    background: $pr-roof;
  }

  &.wintergarten {
    background: $pr-wintergarten;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }


  &.hsd {
    background: $pr-hsd;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.apartment {
    background: $tp-apartment-building;
  }

  &.commercial {
    background: $tp-commercial-and-industrial;
  }

  &.culture {
    background: $tp-culture-and-sacred-buildings;
  }

  &.education {
    background: $tp-education-and-science;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.facade {
    background: $tp-facade;
  }

  &.health {
    background: $tp-health-and-care;
  }

  &.hotels {
    background: $tp-hotels-and-gastronomy;
  }

  &.living {
    background: $tp-living-and-garden;
  }

  &.office {
    background: $tp-office-and-administration;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.other {
    background: $tp-other;
    color: $white;

    .closeIcon {
      color: $white;
    }
  }

  &.stadiums {
    background: $tp-stadiums-and-arenas;
  }

  &.wellness {
    background: $tp-wellness-and-fitness;
  }
}

.closeIcon {
  color: $primary-filter-color;
  width: 12px;
  font-weight: bold;
  margin: 0 0 -12px 13px;
  cursor: pointer;

  @include media('<=tablet') {
    height: 12px;
    margin: 0 0 -3px 13px;
  }
}

