@import '../../_variables.scss';
@import '../../_mixins.scss';

.searchlog {

}

.sort {
  display: flex;
  flex-direction: column;
  margin-left: 5px;

  svg {
    width: 11px;
    height: 6px;

    &:first-of-type {
      margin-bottom: 3px;

    }
  }
}

.expanded {
  color: red;
  path {
    fill: $primary-red;
  }
}

.unauthenticated {
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
}

.results {
  margin-top: 2rem;
  display: block;
  flex-wrap: wrap;
  width: 100%;

  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: 10% 1fr 1fr 1fr;
    padding-left: 0;
    color: $primary-text-color;
    @include proximaNova();

    .header {
      @include brandonGrotesque();
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: 1px solid $primary-text-color;
      padding: 10px 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:first-of-type {
        text-align: left;
      }
    }

    .item {
      color: $primary-text-color;
      @include proximaNova();
      border-bottom: 1px solid $primary-text-color;
      padding: 10px 0;
      text-align: center;

      &.left {
        padding-left: 10px;
        text-align: left;
      }
    }
  }
}
