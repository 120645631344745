@import '../../_variables.scss';
@import '../../_mixins.scss';

.holder {
  position: fixed;
  z-index: 150;
  display: flex;
  height: 100%;

  @include media('<=tablet') {
    left: 0;
    bottom: 0;
    flex-direction: column-reverse;
    width: 100%;
    position: absolute;
  }

  @include media('>tablet') {
    top: 0;
    right: 6rem;
    transform: translateX(100%);
    transition: all 300ms ease-out;

    @include media('>=1800px') {
      width: 30%;
    }

    @include media('<1800px', '>=desktop') {
      width: 45%;
    }

    @include media('<desktop', '>=tablet') {
      width: 80%;
    }

    @include media('<tablet', '>=0') {
      width: 100%;
    }
  }
}

.open {
  height: 100%;

  @include media('>tablet') {
    right: 0;
    transform: translateX(0%);
    transition: all 300ms ease-in;
  }
}

.mobileOpen {
  @include media('<=tablet') {
    transform: translateX(0%) !important;
    transition: transform 300ms ease-in;
    -webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
    box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
    height: 100%;
  }
}

.delayedHeight {
  @include media('<=tablet') {
    height: 68px !important;
  }
}

.side {
  display: flex;

  @include media('<=tablet') {
    & > div:first-of-type {
      background: $primary-overlay-background;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      border-top: 1px solid #cecece;
    }
  }

  @include media('>tablet') {
    background: $grey-85;
    width: 6rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
  }
}

.close {
  @include media('<=tablet') {
    transform: rotate(180deg);
  }

  width: 3.5rem;
  height: 3.5rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  path {
    fill: $primary-grey;
  }

  &:hover {
    cursor: pointer;
  }
}

.main {
  background: $primary-overlay-background;

  @include media('<=tablet') {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 95%;
    transform: translateX(-100%);
    transition: transform 300ms ease-in;
    overflow: hidden;

    @include media('<=phone') {
      width: 100%;
    }
  }

  @include media('>tablet') {
    width: calc(100% - 6rem);
  }
}

.sideContent {
  overflow-y: scroll;
  height: 100%;
  padding: 3rem 2rem;
}

.sideContentTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sideContentLogo {
  display: flex;
  align-items: center;
  padding-right: 1rem;

  img {
    max-width: 198px;
  }
}

.mobileMenu {
  position: absolute;
  z-index: 90;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  padding: .3rem 0;
  justify-content: flex-end;
  background: $primary-mobile-menu;

  @include media('>tablet') {
    display: none;
  }

  & > div {
    margin: 0 .2rem;
    padding: .75rem .3rem .5rem .3rem;
    border-radius: 5px;
    background: $primary-mobile-menu;
    width: 2.25rem;

    span {
      right: -0.7rem;
      z-index: 2;
    }

    svg {
      max-height: 25px;
    }
  }
}
