@import '../../_variables.scss';
@import '../../_mixins.scss';

.content {
  position: absolute;
  z-index: 1;
  bottom: 5.60rem;
  transform: translateX(-50%);
  background: $primary-overlay-background;
  padding-bottom: .75rem;
  filter:  drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.7));
  left: 1.6875rem;

  .close {
    position: absolute;
    right: .5rem;
    top: .5rem;
    padding: 5px 5px 0 5px;
    background: rgba(0,0,0,0.5);

    svg {
      color: white;
      width: 15px;
      height: 15px;
    }

  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 3.4rem solid transparent;
    border-right: 3.4rem solid transparent;
    border-top: 6.7rem solid $primary-overlay-background;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

//.hidden {
//  display: none !important;
//}

.image {
  img {
    max-width: 265px;
  }
}

.spacer {
  padding: 0 .5rem .5rem .5rem;
  position: relative;
}

.product {
  @include brandonGrotesque();
  font-size: 1.35rem;
  color: $primary-text-color;
}

.location {
  @include proximaNova();
  font-size: .9rem;
  font-style: italic;
  color: $primary-text-color;
  padding-bottom: .5rem;
}

.infoRow {
  @include proximaNova();
  font-size: .9rem;
  color: $primary-text-color;
}

.openDetails {
  position: absolute;
  bottom: 0;
  right: 0;

  &:hover {
    cursor: pointer;

    svg path {
      fill: $primary-red;
    }
  }
}

.new {
  position: absolute;
  @include brandonGrotesque();
  text-transform: uppercase;
  font-size: 10px;
  color: $white;
  background: $primary-red;
  padding: 2px 6px;
  border-radius: 6px;
  right: 8px;
  top: 0;
  font-weight: 800;
  z-index: 2;
  line-height: 11px;
}
