@import '../../_variables.scss';
@import '../../_mixins.scss';

.filterItem {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkbox {
  position: relative;
  padding-right: 4rem;
  top: -.75rem;
  
  input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;

    &:checked ~ span:after {
      display: block;
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid $primary-grey;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 2px;
      width: 7px;
      height: 15px;
      border: solid $primary-grey;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.label {
  width: 100%;

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.labelChecked {
  font-weight: bold;
}
