@import '../../_variables.scss';
@import '../../_mixins.scss';

.basic {
  @include brandonGrotesque();
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: 2px solid transparent;
  transition: all 100ms linear;

  svg {
    margin-right: .25rem;
  }

  &:hover {
    transition: all 100ms linear;
    background: $grey-10;
    cursor: pointer;
  }
}

.primaryRed {
  background: $primary-red;
  border-color: $primary-red;
  color: #fff;

  &:hover {
    background: darken($red, 8);
    border-color: darken($red, 8);
  }
}

.redBorder {
  background: $white;
  border-color: $primary-red;
  color: $grey-85;

  &:hover {
    background: darken($red, 8);
    border-color: darken($red, 8);
    color: $white;
  }
}

.blue {
  background: #187CC9;
  color: $white;
  border-radius: 5px;
  @include proximaNova();
  text-transform: unset;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.21px;

  &:hover {
    background: darken(#187CC9, 8);
    color: $white;
  }
}

.grey10 {
  background: $grey-10;
  border-color: $grey-10;
  color: $grey-85;

  &:hover {
    background: $grey-25;
    border-color: $grey-25;
  }
}

.small {
  padding: .35rem 1rem;
  font-size: .75rem;
}

.medium {
  padding: .5rem 1.5rem;
  font-size: .8rem;
}

.large {
  padding: 1rem 3rem;
  font-size: 1.25rem;
}

.thin {
  padding: .5rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  display: flex;
  color: $primary-grey;

  svg {
    font-size: 1.3rem;
  }
}

.disabled {
  opacity: .5 !important;

  &:hover {
    cursor: default !important;
  }
}

.transparent {
  background: transparent;
  border-color: $grey-45;
  color: $white;

  &:hover {
    background: $grey-10;
    border-color: $grey-10;
    color: $grey-85;
  }
}
