@import '../../_variables.scss';
@import '../../_mixins.scss';

.detailPage {
  padding-bottom: 5rem;
  background: $primary-overlay-background;
  h1 {
    font-size: 1.7rem;
  }
}

.gallery {
  margin-top: 1.5rem;
  position: relative;
}

.metaInformation {
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @include media('<=tablet') {
    margin-bottom: 1rem;
  }

  & > div {
    margin-left: 3.5rem;

    @include media('<=desktop') {
      width: 50%;
      margin: .5rem 0;

      &:last-of-type {
        margin-bottom: 4rem;
      }
    }

    @include media('<=tablet') {
      &:last-of-type {
        margin-bottom: 2rem;
      }
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.addToFavorites {
  margin: 2rem 0;
  display: flex;

  .iconFav {
    height: 1.2rem;
    path {
      stroke: $primary-red !important;
      fill: $primary-red !important;
    }
  }

  button {
    min-width: 215px;
  }
}

:global {
  .slick-slider {
    text-align: center;
    .slick-dots {
      position: relative;
      bottom: -10px;
      z-index: 1;
      display: inline !important;
      text-align: center;

      li {
        margin: 0;
      }
    }
  }
}

.addToFavoritesMobile {
  margin-top: 0;
  display: none;

  @include media('<=tablet') {
    z-index: 999;
    margin-top: 0px;
    display: flex;

    .linkTop {
      margin: -8px 0 0 0;

    }

    a {
      margin-right: 15px;
      :global(.fa-minus) {
        margin-top: 9px;
      }

      &.favorite {
        .icon {
          path {
            stroke: $primary-red !important;
            fill: $primary-red !important;
          }
        }
      }
      .icon {
        height: 1.8rem;
        path {
          stroke: $primary-grey !important;
        }

      }
    }
  }

  button {
    min-width: 215px;
  }

}


.description {
  margin-top: 1.5rem;
}

.documents {
  margin-top: 2.5rem;
}

.moreInformation {
  margin-top: 2.5rem;
}

.moreInformationLinks {
  margin-top: 1.5rem;

  & > div {
    margin-top: 1rem;
  }
}

.similarProjects {
  margin-top: 4rem;
}

.similarProjectsList {
  display: flex;
  margin-top: 1.5rem;
  flex-wrap: wrap;

  & > div {
    width: 25%;

    @include media('<=desktop') {
      width: 50%;
      margin-bottom: 1rem;
    }

    @include media('<=tablet') {
      width: 100%;
    }
  }
}

.brochureRequest {
  margin-top: 5rem;
}

.brochureRequestOrder {
  display: inline-block;
  @include brandonGrotesque();
  text-transform: uppercase;
  color: #fff;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  background: $grey-25;
  text-decoration: none;

  @include media('<=tablet') {
    width: 100%;
    display: block;
    text-align: center;
  }

  &:hover {
    background: $primary-red;
  }
}

.icon {
  height: 1.3rem;

  path {
    stroke: $primary-grey !important;
  }
}

.linkTop {
  @include proximaNova();
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  margin-right: 20px;
  padding: 10px 5px 10px 0;
  color: $primary-text-color;
  position: relative;
  text-decoration: none;

  svg {
    path {
      fill: $primary-text-color;;
    }

    margin-right: 5px;
  }

  &:hover {
    background: $primary-teaser-hover;
    cursor: pointer;
  }
}

.shareIcons {
  position: relative;

  svg {
    fill: $primary-text-color;
  }
}
.shareWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background: $primary-overlay-background;
  padding: 1rem;
  box-shadow: 0 0 10px -1px $grey-85;
  @include proximaNova();

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      a {
        display: flex;
        padding: 3px 0;
        color: $primary-text-color;
        text-decoration: none;

        svg {
          flex: 0 0 1rem;
          margin-right: .8rem;
        }
      }

    }
  }
}

.mapIcon {
  path {
    stroke: $primary-text-color !important;
    fill: $primary-text-color !important;
  }
}
