@import '../../_variables.scss';
@import '../../_mixins.scss';

.typologies {
  margin-top: 2rem;
  @include noSelect();
  position: relative;
}

.removeFilters {
  width: 28px;
  position: absolute;
  left: 0;
  top: -55px;

  &:hover {
    cursor: pointer;
  }
}
