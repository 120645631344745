@import '../../_variables.scss';
@import '../../_mixins.scss';

.field {
  @include proximaNova();
  width: 100%;
  border: 2px solid $primary-grey;
  padding: 8px 0 8px 40px;
  -webkit-appearance: none !important;
}

.error {
  border-color: $primary-red;
}
