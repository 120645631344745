@import '../../_variables.scss';
@import '../../_mixins.scss';

.shareButton {
  position: absolute;
  top: 40px;
  right: 120px;
  :hover {
    cursor: pointer;
  }



  svg {
    width: 25px;
    height: 30px;
  }
}

.snippetMessage {
  text-align: center;
  @include proximaNova();
}

.viewSwitch {
  position: absolute;
  z-index: 50;
  bottom: 40px;
  right: 120px;
  display: flex;
  border: 3px solid $primary-grey;
  border-radius: 10px;

  @include media('<=tablet') {
    right: 230px;

    svg {
      color: $primary-overlay-background;
    }

    & > div {
      &.active {
        svg {
          color: $primary-text-color;
        }
      }
    }
  }

  .switchTooltip {
    > div {
      &:first-of-type {
        top: -50px;
        bottom: auto;
        left: -20px;
        width: 120px;
      }
    }
  }


  @include media('<=tablet') {
    position: absolute;
    top: .5rem;
    left: .8rem;
    bottom: auto;
    border-radius: 5px;
    border: none;
    z-index: 91;

    & > div {
      &.active {
        background: $white;
      }
    }
  }

  & > div {
    padding: .5rem;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 1.5rem !important;
      height: 1.5rem;

      @include media('<=tablet') {
        width: 1.75rem !important;
        height: 1.75rem;
      }
    }
  }
}

.active {
  background: $primary-grey;

  @include media('<=tablet') {
    background: $primary-mobile-menu;

  }

  svg {
    color: #fff;
  }
}
