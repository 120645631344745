@import '~normalize.css';

@import 'variables';
@import 'mixins';

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  overflow: hidden;
}

.mapboxgl-map {
  height: 100vh !important;
  min-height: 100vh !important;
  min-height: -webkit-fill-available !important;
  width: 100%;
}

.mapboxgl-marker {
  z-index: 1;
  cursor: inherit !important;
  //left: 1.6875rem !important;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  right: 6rem !important;
}

// workaround for svg colors in drawer
@include media('<=tablet') {
  .item-icon-products,
  .item-icon-history {
    svg path {
      fill: $primary-grey !important;
    }
  }

  .item-icon-typology {
    svg line {
      stroke: $primary-grey !important;
    }
  }

  .item-icon-filter,
  .item-icon-favorites,
  .item-icon-typology {
    svg path {
      stroke: $primary-grey !important;
    }
  }

  ._hj_feedback_container {
    button {
      bottom: 47px !important;
    }
  }
}
