@import '../../_variables.scss';
@import '../../_mixins.scss';

.settings {
  margin-top: 1rem;
}

.imprint {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  a:first-of-type {
    margin-right: 15px;
  }
}

.copyright {
  margin-top: 1rem;
  text-align: center;

  a {
    color: $grey-65;
    text-decoration: none;
    @include proximaNova();
    font-size: .9rem;

    &:nth-of-type(3) {
      margin-left: .25rem;
    }

    &:hover {
      cursor: pointer;
      color: $primary-red;
    }
  }
}

.contact {
  position: fixed;
  bottom: 50px;
  width: calc(100% - 7rem);
  left: 6rem;


  p {
    text-align: center;
    @include proximaNova();
    color: $primary-text-color;
  }

  a {
    color: $primary-red;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.iconsFooter {
  position: fixed;
  bottom: 0;
  background: $primary-text-color;
  width: calc(100% - 7rem);
  left: 6rem;
  display: flex;
  flex-direction: column;
  padding: .5rem 0;

  p {
    text-align: center;
    @include proximaNova();
    color: $primary-overlay-background;
  }

  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      margin: 0 5px;

      a {
        color: $primary-overlay-background;
        font-size: 24px;

        &:hover {
          opacity: .8;
        }
      }

      .blogIcon {
        fill: $primary-overlay-background;
        height: 24px;
      }
    }


  }
}

.label {
  @include proximaNova();
  display: block;
  color: $primary-text-color;
}

.select {
  margin-top: .5rem;
  display: block;
  width: 100%;
  padding: .5rem .5rem;
  @include proximaNova();
  -webkit-appearance: none !important;
}

.darkModeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.darkModelabel {
  @include proximaNova();
  font-weight: bold;
  color: $primary-text-color;
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 26px;
  align-self: flex-end;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey-10;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 3px;
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary-red;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary-red;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
