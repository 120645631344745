@import '../../_variables.scss';
@import '../../_mixins.scss';

.search {

}

.icon {
  height: 1.8rem;
  path {
    stroke: $primary-grey !important;
  }

  &.copied {
    path {
      fill: $primary-green !important;
    }
  }

  &:hover {
    cursor: pointer;
  }

}

.copyUrl {
  display: grid;
  grid-template-columns: 90% 10%;

  .icon {
    transition: color 0.5s;
    margin-top: 36px;
    margin-left: 1rem;
  }
}

.share {
  margin: 4rem 0 1rem 0.5rem;
  @include proximaNova();
  position: relative;
}


.shareOverlay {
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 40%;
}

.box {
  @include brandonGrotesque();
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3rem;
  border: none;
  border-bottom: 1px solid $primary-text-color;
  display: block;
  width: 100%;
  letter-spacing: 2px;
  color: $primary-text-color;
  padding-bottom: 1rem;
  background: $primary-overlay-background;

  &::placeholder {
    color: $primary-text-color;
  }

  @include media('<=tablet') {
    font-size: 2rem;
  }
}

.helpTextWrapper {
  position: relative;
}

.helpText {
  position: absolute;
  right: 0;
  top: .5rem;
  @include proximaNova();
  color: $primary-text-color;
}

.resultsCount {
  position: absolute;
  left: 0;
  top: .5rem;
  @include proximaNova();
  color: $primary-text-color;
}

.results {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 33%;
    margin: 1rem 0;

    @include media('<=desktop') {
      width: 50%;
    }

    @include media('<=tablet') {
      width: 100%;
    }
  }
}
