@import '../../_variables.scss';
@import '../../_mixins.scss';

.help {

}

.restartTour {
  position: absolute;
  top: 40px;
  left: 130px;
}

.headline {
  @include brandonGrotesque();
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: $primary-text-color;
  font-size: 1.4rem;
  letter-spacing: .1rem;
}

h2 {
  margin-top: 2rem !important;
}

.description {
  color: $primary-text-color;
  @include proximaNova;
  margin-top: 2rem;
  white-space: pre-line;

  > svg {
    line, path {
      stroke: $primary-text-color !important;
    }
  }
}

.icons {
  max-width: 80%;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: -1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  @include media('<=tablet') {
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.navigationIcons {
  max-width: 80%;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  @include media('<=tablet') {
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .navigationIcon {
    text-align: center;
    margin-top: 1rem;
    background: $grey-85;
    padding: .5rem 1rem;

    svg {
      height: 40px;
    }
  }
}

.productIcons {
  max-width: 80%;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  .productIcon {
    text-align: center;
    margin-top: 1rem;

    svg {
      height: 50px;
      width: 50px;

      path {
        &:first-of-type {
          fill: $primary-text-color !important;
        }
      }
    }
  }

  @include media('<=tablet') {
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }

  svg {
    text-align: center;
  }
}

.mapSwitch {
  margin-bottom: 2rem;
}

.mapDetails {
  display: flex;
}

.mapImage {
  padding-right: 1rem;
  img {
    width: 100%;
  }

  span {
    display: block;
    padding-top: .5rem;
    font-style: italic;
  }

  &:last-of-type {
    span {
      padding-left: .7rem
    }
  }
}

.svg {
  width: 1.5rem;
  height: 1.5rem
}

.icon {
  position: relative;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.2);
}

.marker {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -100%);
  filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.3));
  position: relative;
  top: 0.825rem;
  left: 50%;
  margin-top: 5rem;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 1.563rem solid transparent;
    border-right: 1.563rem solid transparent;
    border-top: 3.125rem solid transparent;
    position: absolute;
    top: 2.5rem;
    left: 0.188rem;
  }
}

.apartmentBuilding {
  background: $tp-apartment-building;

  &:after {
    border-top-color: $tp-apartment-building;
  }

  svg path, svg line {
    stroke: #fff;
  }
}

.commercialAndIndustrial {
  background: $tp-commercial-and-industrial;

  &:after {
    border-top-color: $tp-commercial-and-industrial;
  }

  svg path, svg line, svg rect {
    stroke: #fff;
  }
}

.cultureAndSacredBuilding {
  background: $tp-culture-and-sacred-buildings;

  &:after {
    border-top-color: $tp-culture-and-sacred-buildings;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.educationAndScience {
  background: $tp-education-and-science;

  &:after {
    border-top-color: $tp-education-and-science;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.facade {
  background: $tp-facade;

  &:after {
    border-top-color: $tp-facade;
  }

  svg path, svg line {
    stroke: #fff;
  }
}

.healthAndCare {
  background: $tp-health-and-care;

  &:after {
    border-top-color: $tp-health-and-care;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.hotelsAndGastronomy {
  background: $tp-hotels-and-gastronomy;

  &:after {
    border-top-color: $tp-hotels-and-gastronomy;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.livingAndGarden {
  background: $tp-living-and-garden;

  &:after {
    border-top-color: $tp-living-and-garden;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.officeAndAdministration {
  background: $tp-office-and-administration;

  &:after {
    border-top-color: $tp-office-and-administration;
  }

  svg path, svg line, svg rect, svg circle {
    stroke: #fff;
  }
}

.other {
  background: $tp-other;

  &:after {
    border-top-color: $tp-other;
  }

  svg path, svg line, svg rect, svg circle {
    fill: #fff;
  }
}

.stadiumsAndArenas {
  background: $tp-stadiums-and-arenas;

  &:after {
    border-top-color: $tp-stadiums-and-arenas;
  }

  svg path, svg line, svg rect, svg circle, svg ellipse {
    stroke: #fff;
  }
}

.wellnessAndFitness {
  background: $tp-wellness-and-fitness;

  &:after {
    border-top-color: $tp-wellness-and-fitness;
  }

  svg path, svg line, svg rect, svg circle, svg ellipse {
    stroke: #fff;
  }
}