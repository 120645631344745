@import '../../_variables.scss';
@import '../../_mixins.scss';

.gridContainer {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  gap: 0;
}

.color {
  grid-area: 1 / 1 / 8 / 10;
  box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);

  @include media('<desktop') {
    grid-area: 1 / 1 / 13 / 13;
  }
}

.text {
  grid-area: 2 / 2 / 6 / 9;

  @include media('<desktop') {
    grid-area: 2 / 2 / 12 / 12;
  }

  h2 {
    @include proximaNova();
    color: #fff;
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: bold;
  }

  p {
    @include proximaNova();
    color: #fff;
    margin-bottom: 0;
  }
}

.image {
  grid-area: 6 / 5 / 13 / 13;
  box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
  display: flex;

  @include media('<desktop') {
    grid-area: 7 / 1 / 13 / 13;
    margin-top: 1.5rem;
  }

  @include media('<tablet') {
    grid-area: 6 / 1 / 13 / 13;
    margin-top: 4.5rem;
    display: none;
  }

  img {
    max-width: 100%;
  }
}

.button {
  margin-top: 1.5rem;
  @include brandonGrotesque();
  font-size: 1rem;
  color: #fff;
  border: 2px solid #fff;
  display: inline-block;
  padding: .75rem 1.75rem;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background: #fff;
    color: $grey-85;
  }
}

.pullTop {
  margin-top: -5rem;
}

