@import '../../_variables.scss';
@import '../../_mixins.scss';

.cluster {
  background: rgba(213, 17, 48, .8);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  @include brandonGrotesque();
  font-size: 1.5rem;
  position: relative;
  transform: translate(-50%, -100%);
  animation: pulse-red 2s infinite;
}

.small {
  width: 3rem;
  height: 3rem;
}

.medium {
  width: 4.25rem;
  height: 4.25rem;
}

.large {
  width: 5.5rem;
  height: 5.5rem;
}


@keyframes pulse-red {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(213, 17, 48, .8);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(213, 17, 48, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
