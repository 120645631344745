@import '../../_variables.scss';
@import '../../_mixins.scss';

.projectSlider {
  margin-top: 4rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  & > div {
    display: flex;
    align-items: center;
    @include proximaNova();
    font-weight: bold;
    color: $primary-grey;

    span {
      margin: 0 .5rem;
    }
  }
}

.projectSliderIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.projectSliderPrevious, .projectSliderNext {
  @include media('<=tablet') {
    border: 1px solid $primary-grey;
    padding: .5rem 2rem;
    display: flex;
    justify-content: center;
  }

  svg {
    path {
      fill: $primary-grey;
    }
  }

  &:hover {
    cursor: pointer;

    span {
      color: $primary-red;
    }

    svg {
      path {
        fill: $primary-red;
      }
    }
  }
}

.projectSliderNext {
  svg {
    transform: rotate(180deg);
  }
}
