@import '../../_variables.scss';
@import '../../_mixins.scss';

.referenceSubmit {

}

.unauthenticated {
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
}

.svg {
  width: 1.5rem;
  height: 1.5rem
}

.reason {
  width: 100%;
  height: 100px;
  @include proximaNova();
}

.results {
  margin-top: 2rem;
  display: block;
  flex-wrap: wrap;
  width: 100%;

  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 2fr 8%;
    padding-left: 0;
    color: $primary-text-color;
    @include proximaNova();

    .header {
      @include brandonGrotesque();
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: 1px solid $primary-text-color;
      padding: 10px 5px;
      text-align: left;
      display: flex;

      &.left {
        padding-left: 10px;
        text-align: left;
      }
    }

    .item {
      color: $primary-text-color;
      @include proximaNova();
      border-bottom: 1px solid $primary-text-color;
      padding: 10px 5px;
      text-align: left;

      &.status {
        font-weight: bold;
      }

      &.edit {
        text-align: center;
        display: flex;
        svg {
          margin-left: 5px;
          width: 17px;


          &:hover {
            cursor: pointer;
          }
        }
      }

      &.left {
        padding-left: 10px;
        text-align: left;
      }
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  @include noSelect();
}

.iconLeft {

  svg {
    fill: $primary-text-color;
  }

  &:hover {
    cursor: pointer;
  }


  svg {
    width: 1rem;
    height: 1rem;
  }
}

.page {
  margin: 0 1rem;
  position: relative;
  top: -1px;
  color: $primary-text-color;
  @include brandonGrotesque();
}

.iconRight {

  svg {
    fill: $primary-text-color;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    transform: rotate(180deg);
    width: 1rem;
    height: 1rem;
  }
}

.statusSelect {
  position: absolute;
  top: -98px;
  right: 80px;
}

.modalText {
  @include proximaNova();
}

.section {
  display: flex;
  @include proximaNova();
  padding: 20px;

  .element {
    color: $grey-85;
    padding-right: 50px;
    min-width: 30%;

    &.half {
      min-width: 50%;
    }

    &.right {
      margin-left: auto;
      padding-right: 60px;
      min-width: auto;
    }

    &.fullWidth {
      min-width: 100%;
    }

    .label {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $grey-65;
      padding-bottom: 5px;

      &.noValue {
        margin-top: 18px
      }
    }

    .value {
      font-size: 18px;
      font-weight: 600;
      display: block;

      a {
        color: $red;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &.colored {
    background: $grey-10;
    padding-left: 40px;
    margin-left: -20px;
    margin-top: 20px;
  }
}

.success {
  color: $green;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.filter {
  max-width: 30%;
}

.direction {
  padding-left: 5px;
  display: flex;
  flex-direction: column;

  .selected {
    path {
      fill: $red;
    }
  }
  svg {
    path {
      fill: $grey-25;
    }
    width: 10px;
    height: 10px;
  }
}

