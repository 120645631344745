@import '../../_variables.scss';
@import '../../_mixins.scss';

.notifications {

}

.headline {
  @include brandonGrotesque();
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: $primary-text-color;
  font-size: 1.4rem;
  letter-spacing: .1rem;
}

.titleAction {
  display: flex;
  align-items: center;

  h2 {
    margin-right: 10px;
  }
}

.greyBar {
  background: $grey-45;
  height: calc(100% - 20px);
  width: 3px;
  margin: 0 auto;
}

.heading {
  display: flex;

  @include media('<=tablet') {
    display: block;
    h1 {
      margin-bottom: 15px;
    }
  }

  button {
    margin-left: auto;
  }
}

.results {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 10px;

  @include media('<=tablet') {
    grid-template-columns: 50% 50%;
  }
}

.markRead {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.noReferences {
  @include proximaNova;
  margin-top: 1rem;
}