@import '../../_variables.scss';
@import '../../_mixins.scss';

.item {
  @include media('<=tablet') {
    margin: .5rem 0;
  }

  @include media('>tablet') {
    margin: .75rem 0;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.25rem;
  height: 3.25rem;
  position: relative;
  transition: transform .2s;
  
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
    border-radius: 10px;
    background: rgba(255, 255, 255, .5);
  }

  img {
    max-width: 75%;
    border-radius: 50%;
  }
}

.active {
  border-radius: 10px;

  @include media('<=tablet') {
    background: rgba(0, 0, 0, .1);
  }

  @include media('>tablet') {
    background: rgba(255, 255, 255, .5);
  }
}

.desktopOnly {
  @include media('<=tablet') {
    display: none !important;
  }
}

.mobileOnly {
  @include media('>tablet') {
    display: none !important;
  }
}

.badge {
  @include proximaNova();
  background: $primary-red;
  color: #fff;
  width: 1.65rem;
  height: 1.65rem;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: -.3rem;
  right: -.3rem;
}
