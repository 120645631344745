@import '../../_variables.scss';
@import '../../_mixins.scss';

.projectTeaser {
  padding: 0 .5rem;

  &:hover {
    cursor: pointer;
  }
}

.image {
  position: relative;
  
  img {
    max-width: 100%;
  }
}

.topProject {
  position: absolute;
  left: 0;
  bottom: .2rem;
  width: 100%;
  background: rgba(213, 17, 48, .8);
  @include brandonGrotesque();
  text-transform: uppercase;
  color: #fff;
  font-size: .8rem;
  padding: .2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 .2rem;
  }
}

.title {
  @include brandonGrotesque();
  color: $primary-text-color;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: .5rem;

  @include media('<=tablet') {
    font-size: 18px;
  }
}

.refId {
  @include proximaNova();
  color: $primary-text-color;
  margin-top: .25rem;
  font-style: italic;
}

.product {
  @include proximaNova();
  color: $primary-text-color;
  margin-top: .25rem;

  @include media('<=tablet') {
    font-size: 17px;
  }
}

.new {
  position: absolute;
  @include brandonGrotesque();
  text-transform: uppercase;
  font-size: 10px;
  color: $white;
  background: $primary-red;
  padding: 4px 7px;
  border-radius: 4px;
  right: 10px;
  bottom: 15px;
  font-weight: 800;
  z-index: 2;
  line-height: 8px;

  @include media('<=tablet') {
    font-size: 12px;
    padding: 6px 10px;
  }
}

.projectTeaserExport {
  width: 18px;
  height: 18px;
  background: $white;
  border-radius: 20px;
  box-shadow: 0 0 11px 0 $primary-text-color;
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.projectTeaserExportSelected {
  background: $primary-red;
}