@import '../../../_variables.scss';
@import '../../../_mixins.scss';

.field {
  @include proximaNova();
  width: 100%;
  border: 2px solid $primary-grey;
  padding: 8px 0 8px 8px;
  -webkit-appearance: none !important;
}

.label {
  @include proximaNova();
  color: $primary-grey;
  font-size: 18px;
  letter-spacing: 0.48px;
  line-height: 24px;
  font-weight: bold;
  display: inline-block;
  width: 100%;

  .labelSub {
    font-size: 16px;
    font-weight: normal;
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
  }
}

.error {
  border-color: $primary-red;
}
