@import '../../_variables.scss';
@import '../../_mixins.scss';

.referenceSubmit {

}

.unauthenticated {
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
}

.form {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: block;
  flex-wrap: wrap;
  width: 100%;

  .mandatory {
    color: $red;
  }

  .right10 {
    margin-right: 10%;
  }


  .formSection {
    width: 100%;
    display: inline-block;
    margin: 15px 0;
    padding: 0 10px;



    &:last-of-type {
      border-bottom: none;
    }
  }

  .formHeadline {
    font-size: 18px;
    letter-spacing: 0.54px;
    @include proximaNova();
    line-height: 36px;
    font-weight: 600;
    color: $grey-85;
    display: inline-block;
    width: 100%;
    padding: 0 10px;

    &.grey-10 {
      background: $grey-10;
      margin-top: 20px;
      padding: 10px;
    }

    &.noPadding {
      padding: 0;
    }
  }

  .formSubline {
    color: $grey-85;
    @include proximaNova();
    letter-spacing: 0.48px;
    font-size: 16px;
    width: 100%;
    padding: 0 10px;
  }

  .formElement {
    min-width: 45%;
    float: left;

    @include media('<tablet') {
      min-width: 100%;
    }


    &.small {
      min-width: 18.5%;
      max-width: 18.5%;
      margin-right: 1.5%;
    }

    &.small {
      min-width: 18.5%;
      max-width: 18.5%;
      margin-right: 1.5%;

      @include media('<tablet') {
        min-width: 100%;
        max-width: 100%;
      }
    }

    &.fullWidth {
      min-width: 100%;
      margin-right: 0 !important;
    }

    &.checkbox {
      min-width: 23%;
      max-width: 23%;
      margin-right: 2%;

      @include media('<tablet') {
        min-width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }


      &.wide {
        min-width: 28%;
        max-width: 28%;
        margin-right: 2%;

        @include media('<tablet') {
          min-width: 100%;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }

    &.zip {
      min-width: 10%;
      max-width: 10%;
      margin-right: 5%;

      @include media('<tablet') {
        max-width: 30%;
      }
    }

    &.city {
      min-width: 30%;
      max-width: 30%;
      margin-right: 0;

      @include media('<tablet') {
        max-width: 65%;
        min-width: 65%;

      }
    }

    &.system {
      min-width: 28%;
      max-width: 28%;
      margin-bottom: 30px;
      margin-right: 4%;

      @include media('<tablet') {
        max-width: 75%;
        min-width: 75%;
        margin-bottom: 15px;
      }
    }

    &.icon {
      min-width: 15%;
      max-width: 15%;
      margin-top: 38px;

      svg {
        width: 30px;
        fill: $primary-grey;

        &:hover {
          cursor: pointer;
          fill: $primary-text-color
        }
      }
    }
  }
}

.thankYou {
  @include proximaNova();
  color: $primary-text-color;
}

.dropzoneWrapper {
  background: #DCDCDC;
  border: 1px solid $primary-grey;
  padding: 8px;
  margin-top: 15px;

  .dropzone {
    text-align: center;
    padding: 20px;
    border: 1px dashed $primary-grey;
    @include proximaNova();
    width: 100%;
    min-height: 200px;
    margin: auto;

    .textCenter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: auto;
      flex: 1;
      min-height: 200px;
      font-weight: bold;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        font-size: 18px;
      }
    }

    .dropzoneContent {

    }
  }
}

.imageError {
  color: $red;
  @include proximaNova();
  padding-left: 10px;
}

.imageGrid {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

