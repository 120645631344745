@import '../../_variables.scss';
@import '../../_mixins.scss';

.tooltip {
  position: absolute;
  background: $primary-text-color;
  color: $primary-overlay-background;
  visibility: hidden;
  padding: 5px;
  border-radius: 5px;
  bottom: 80%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  text-align: center;
  opacity:0;
  transition:visibility 0.4s linear,opacity 0.4s linear;

  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 40px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.4s ease-in-out;
  }
}

.tooltipWrapper {
  position: relative;
}
