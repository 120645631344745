@import '../../_variables.scss';
@import '../../_mixins.scss';

.iconLink {
  margin: .5rem 0;
  display: flex;
  align-items: center;
}

.iconLinkItem {
  display: inline-flex;
  align-items: center;

  path {
    fill: $primary-grey;
  }

  &:hover {
    cursor: pointer;

    .icon {
      line, path {
        fill: $primary-red;
      }
    }

    .label {
      color: $primary-red;
    }
  }
}

.icon {

}

.label {
  @include proximaNova();
  color: $primary-grey;
  padding-left: .5rem;
}
